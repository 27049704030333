.listCard {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  user-select: none;
  cursor: pointer; /* Cambia el cursor al pasar sobre la tarjeta */
}

.activeCard {
  background-color: rgb(0, 0, 190);
  color: white;
}