.card {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .card-image-container {
    flex-shrink: 0; /* Evita que la imagen se estire */
  }
  
  .card-image {
    width: 100px; /* Ajusta el ancho de la imagen según tus necesidades */
    height: auto; /* Ajusta la altura de la imagen según tus necesidades */
  }
  
  .card-content {
    /* Estilos adicionales para card-content */
  }
  