.log-table {
  max-width: 100%;
  max-height: 91%;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: auto;
  padding-left: 10px;
  box-sizing: border-box; /* Agregado */
}
/* Estilo de la barra de desplazamiento vertical */
.log-table::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.log-table::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color de la barra de desplazamiento */
  border-radius: 4px; /* Bordes redondeados */
}

.log-table::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color del fondo de la barra de desplazamiento */
}

/* Estilo de la barra de desplazamiento horizontal */
.log-table::-webkit-scrollbar-horizontal {
  height: 8px; /* Altura de la barra de desplazamiento horizontal */
}

.log-table::-webkit-scrollbar-thumb:horizontal {
  background-color: #ccc;
  border-radius: 4px;
}

.log-table::-webkit-scrollbar-track:horizontal {
  background-color: #f1f1f1;
}
