.container-with-scroll {
  width: 100%; /* O el ancho que desees */
  height: 100%; /* O la altura que desees */
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  /* overflow: hidden; */
}

.content {
  padding: 10px; /* Espacio para separar el contenido del borde */
  transition: scrollbar-width 0.5s; /* Transición suave para la visibilidad */
  
}

/* .content.show-scrollbar {
  scrollbar-width: thin; 
} */


/* Personaliza la apariencia de la barra de desplazamiento */
.container-with-scroll::-webkit-scrollbar {
  width: 5px; /* Ancho de la barra de desplazamiento */
}

.container-with-scroll::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Color de fondo de la barra */
  border-radius: 100px; /* Radio del borde */
}