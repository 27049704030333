.log-row {
    max-width: 100%; /* Asegura que las filas no sean más anchas que el contenedor */
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }
  
  .log-column {
    flex-shrink: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  
  .log-narrow {
    width: 120px;
  }
  
  .log-small {
    width: 80px;
  }
  
  .log-large {
    flex-grow: 1;
    max-width: calc(
      100% - 320px
    ); /* Resta el ancho de las otras columnas para obtener el máximo disponible para log-message */
    word-wrap: break-word; /* Permite saltos de línea en palabras */
  }
  
  .log-message {
    text-align: left;
  }
  
  .log-action {
    display: flex;
    align-items: flex-end;
  }
  
  .close-button {
    background-color: #ccc;
    border: none;
    color: #fff;
    padding: 5px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    align-self: center; /* Alinea verticalmente el contenido del botón */
  }
  