/* TuArchivoDeEstilos.css */

.tarjeta {
  border-radius: 10px;
  width: 100%;
  margin: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 70vh; /* Cambiado de 300px a 80% de la altura visible */
}
.titulo {
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 10px;
  color: #888;
  font-size: 14px; /* Tamaño de fuente más pequeño */
}
